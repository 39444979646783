var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [_c("page-snippet", { attrs: { name: "dashboard-welcome" } })],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.providerNotices, function(pd) {
        return _c(
          "b-row",
          { key: pd.ProviderID },
          [
            _c(
              "b-col",
              [
                _c(
                  "b-alert",
                  { attrs: { variant: "warning", show: "" } },
                  [
                    _c("p", { staticClass: "mb-2" }, [
                      _c("strong", [
                        _vm._v("Notice for provider: " + _vm._s(pd.Description))
                      ])
                    ]),
                    pd.Additional_Dashboard_SnippetName
                      ? _c("page-snippet", {
                          attrs: { name: pd.Additional_Dashboard_SnippetName }
                        })
                      : _vm._e(),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(pd.Additional_Dashboard_SnippetHTML)
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "b-row",
        [
          _vm.$root.$data.me.access.VW1
            ? _c(
                "b-col",
                {
                  staticClass: "mt-4",
                  attrs: { lg: "4", md: "6", cols: "12" }
                },
                [
                  _c(
                    "b-card",
                    { attrs: { header: "Recent releases" } },
                    [
                      _c("release-widget", {
                        attrs: {
                          sortBy: "ReleaseDate",
                          sortDesc: true,
                          filter: { ReleaseStatus: "recently-released" },
                          "show-delivery-status": false
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$root.$data.me.access.VW1
            ? _c(
                "b-col",
                {
                  staticClass: "mt-4",
                  attrs: { lg: "4", md: "6", cols: "12" }
                },
                [
                  _c(
                    "b-card",
                    { attrs: { header: "Upcoming releases" } },
                    [
                      _c("release-widget", {
                        attrs: {
                          sortBy: "ReleaseDate",
                          sortDesc: false,
                          filter: { ReleaseStatus: "upcoming-release" },
                          "show-delivery-status": false
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$root.$data.me.access.VW1
            ? _c(
                "b-col",
                {
                  staticClass: "mt-4",
                  attrs: { lg: "4", md: "6", cols: "12" }
                },
                [
                  _c(
                    "b-card",
                    { attrs: { header: "To be delivered" } },
                    [
                      _c("release-widget", {
                        attrs: {
                          sortBy: "ReleaseDate",
                          sortDesc: false,
                          filter: { ReleaseStatus: "to-be-delivered" },
                          "show-delivery-status": true
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$root.$data.me.access.TR1
            ? _c(
                "b-col",
                { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "6" } },
                [
                  _c("TrendList", {
                    attrs: {
                      trendFilter: _vm.trendFilter,
                      perPage: 25,
                      aggName: "tracks",
                      title: "Top Tracks",
                      subTitle: "Streams of the past 7 days"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }