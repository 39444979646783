<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <page-snippet name="dashboard-welcome" />
      </b-col>
    </b-row>

    <b-row v-for="pd in providerNotices" :key="pd.ProviderID">
      <b-col>
        <b-alert variant="warning" show>
          <p class="mb-2"><strong>Notice for provider: {{ pd.Description }}</strong></p>
          
          <page-snippet v-if="pd.Additional_Dashboard_SnippetName" :name="pd.Additional_Dashboard_SnippetName" />
          <div v-html="pd.Additional_Dashboard_SnippetHTML"></div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4" md="6" cols="12" class="mt-4" v-if="$root.$data.me.access.VW1">
        <b-card header="Recent releases">
          <release-widget 
              sortBy="ReleaseDate"
              :sortDesc="true"
              :filter="{ ReleaseStatus: 'recently-released' }"
              :show-delivery-status="false"
            />
        </b-card>
      </b-col>

      <b-col lg="4" md="6" cols="12" class="mt-4" v-if="$root.$data.me.access.VW1">
        <b-card header="Upcoming releases">
          <release-widget 
              sortBy="ReleaseDate"
              :sortDesc="false"
              :filter="{ ReleaseStatus: 'upcoming-release' }"
              :show-delivery-status="false"
            />        
        </b-card>
      </b-col>

      <b-col lg="4" md="6" cols="12" class="mt-4" v-if="$root.$data.me.access.VW1">
        <b-card header="To be delivered">
          <release-widget 
              sortBy="ReleaseDate"
              :sortDesc="false"
              :filter="{ ReleaseStatus: 'to-be-delivered' }"
              :show-delivery-status="true"
            />        
        </b-card>
      </b-col>

      <b-col lg="4" md="6" cols="6" class="mt-4" v-if="$root.$data.me.access.TR1">
        <TrendList :trendFilter="trendFilter" :perPage="25" aggName="tracks" title="Top Tracks" subTitle="Streams of the past 7 days" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import PageSnippet from '@/components/PageSnippet'
  import moment from 'moment'

  export default {
    name: 'Dashboard',
    components: {
      PageSnippet,
      'release-widget': () => import('@/components/ReleaseWidget'),
      'TrendList': () => import('@/components/TrendList.vue'),
    },
    props: {

    },
    data () {
      return {
        perpage: 1,
        results: '',
        showDeliveryConsent: false,
        trendFilter: {
          timerange: '7d',
          dateFrom: '',
          dateTo: '',
          eventType: 'stream',
          selectedTracks: [],
          selectedAlbums: [],
          selectedAttributions: [],
        }
      }
    },
    methods: {
      testNotification() {
        this.$notify({
          type: 'success',
          text: 'Test Success'
        })
      },
      dateNow () {
        return moment().format('YYYY-MM-DD')
      },
      sendMail (param) {
      this.$http.put(param)
        .then(response => {
          this.results = response.data
        })
      },
    },
    computed: {
      providerNotices () {
        if (!this.$root.$data.me.providerData) return []
        if (this.$root.$data.me.IsSuperUser === 1) return []

        return this.$root.$data.me.providerData.filter(pd => {
          return (pd.Additional_Dashboard_SnippetName || pd.Additional_Dashboard_SnippetHTML)
        })
      },
    },
    mounted () {

    }
  }
</script>

<style scoped>

</style>
